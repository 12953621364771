import { Scroll } from "@react-three/drei";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const Section = (props) => {

    const { children,mobileTop } = props

    return (
        <motion.section
            className={`
        h-screen w-screen p-8 max-w-screen-2xl mx-auto
        flex flex-col items-start
        ${mobileTop? "justify-start md:justify-center": "justify-center"}
        `}
        
            initial={{
                opacity: 0,
                x: -50,
            }}
            whileInView={{
                opacity:1,
                x:50,
                transition: {
                    duration:1,
                    delay:0.7,
                }

            }}
        >
            {children}
        </motion.section>
    )

}


export const Interface1 = () => {
    return (
        <Scroll html horizontal>
             <div className="flex items-center min-h-screen overflow-x-auto"
              
             > 
         {/* <div className="flex flex-col items-center w-screen">  */}

            <AboutSection />
            
            <Designtalk/>
            <Developtalk/>
            
            {/* <div style={{ minWidth: "5650px" }} /> this was for space between about content content and the form
<ContactSection/> */}





        </div>
</Scroll>
    )
}



const AboutSection = () => {

    return (
        <Section mobileTop>
           
            <h1 className="text-3xl md:text-5xl text-white font-extrabold text-nowrap  md:leading-snug  mt-28 md:mt-0">
                The cosmic blend by
                <br />
                <div className="mt-3 md:mt-2"/>
                <span className=" bg-white text-black px-0.5 md:px-1 text-nowrap italic"> Jayant Chopra </span>
            
            </h1>
            <motion.p className=" text-sm md:text-lg text-gray-500 mt-4"
initial ={{
    opacity:0,
    x:-25,
}}
whileInView={{
    opacity:1,
    x:0,
}}
transition={{
    duration:1,
    delay: 1,

}}
            >
                Designer by day,
                <br />
                Developer by night

            </motion.p>
            {/* <motion.button className={`bg-indigo-600 text-white py-4 px-8 rounded-lg font-bold text-lg mt-16`} */}
            {/* <motion.button className={'invisible md:visible text-white transition ease-in-out delay-150 bg-indigo-600 hover:-translate-y-1 hover:bg-indigo-400 duration-300 py-4 px-8 rounded-lg font-bold text-md md:text-lg mt-4 md:mt-16'}

      initial ={{
        opacity:0,
        x:-25,
    }}
    whileInView={{
        opacity:1,
        x:0,
    }}
    transition={{
        duration:1,
        delay: 1,
    
    }}



      >
                Learn More
                
            </motion.button> */}
            

<svg class="h-20 w-20 mt-64 md:mt-80 fixed left-3 text-white animate-pulse"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round">  <polyline points="13 17 18 12 13 7" />  <polyline points="6 17 11 12 6 7" /></svg>





        </Section>
    )
}



const Designtalk = () => {

return(
<motion.div className=" invisible md:visible border-0 w-auto text-pretty designtalk-background rounded-xl overflow-hidden flex flex-col "
 initial={{ opacity: 0,}}
 animate={{ opacity: 1, }}
 transition={{ duration: 1, delay: 1 }}
 style={{ marginLeft: 2000 }} 
>

<svg class="h-16 w-16 mx-4 mt-2 text-white"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="2" y="6" width="20" height="12" rx="2" />  <path d="M6 12h4m-2 -2v4" />  <line x1="15" y1="11" x2="15" y2="11.01" />  <line x1="18" y1="13" x2="18" y2="13.01" /></svg>
              
               <motion.p className="text-left  text-gray-100 px-6 my-4"
               
               initial ={{
                opacity:0,
                x:50,
            }}
            whileInView={{
                opacity:1,
                x:0,
            }}
            transition={{
                duration:1,
                delay: 1,
            
            }}
               >
                
                He strives to blend creativity with user-centric design to elevate 
                <br/>
                the joy of interaction.
               
               </motion.p>
           
        
 </motion.div>
)
}


const Developtalk = () => {

    return(
    <motion.div className="invisible md:visible text-balance border-0 designtalk-background  rounded-xl overflow-hidden flex flex-col "
     initial={{ opacity: 0,}}
     animate={{ opacity: 1, }}
     transition={{ duration: 1, delay: 1 }}
     style={{ marginLeft: 4300, }} 
    >

<svg class="h-16 w-16 mx-4 mt-2 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"/>
</svg>
    
                  
                   <motion.p className="text-left text-gray-100 px-6 my-4"
                   
                   initial ={{
                    opacity:0,
                    x:50,
                }}
                whileInView={{
                    opacity:1,
                    x:0,
                }}
                transition={{
                    duration:1,
                    delay: 1,
                
                }}
                   >
                    Fueled by his passion for coding, he can not only just design but also bring exhilarating ideas to life.

                   </motion.p>
               
            
     </motion.div>
    )
    }

