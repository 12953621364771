import { extend } from '@react-three/fiber'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import myFont from './fonts/ExpletusSans-Bold.ttf'
import { Text } from '@react-three/drei'

extend({ TextGeometry })

export default function Text1() {
const font = new FontLoader().parse(myFont);

return(
    <Text
      position={[0, 10, 0]}
      color="gold"
      fontSize={5}
      lineHeight={1}
      letterSpacing={0.02}
      font={font}
    >
      {'test'}
    </Text>
)
}