import React, { Suspense, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, BrowserRouter } from "react-router-dom";

import Page1 from "./page1";

import { Cursor } from "./components/Cursor";
import { Menu } from "./components/Menu";
import { ScrollManager } from "./components/ScrollManager";
import { MenuManager } from "./components/MenuManager";
import { animate, useMotionValue } from "framer-motion";
import { Canvas, useFrame } from "@react-three/fiber";
import Menu_cam from "./components/MenuAni";
import { LoadingScreen } from "./components/LoadingScreen";
import { AudioButton } from "./components/AudioComp";


function Overlay() {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none', width: '100%', height: '100%', zIndex:'100' }}>
    
      <div className=" invisible md:visible bottom-4 right-4 md:bottom-12 md:right-12 p-3" style={{ position: 'absolute', fontSize: '13px', color: '#4f46e5' }}>© &nbsp; Jayant Chopra</div>
    </div>
  )
}


function MenuOverlay()
{
  return(
    <div
    className="menu-background"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        zIndex: 1, // Ensure the overlay appears above other elements
      }}
    >
      <div
     className="menu-background"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.5)', // Adjust the alpha value for the desired opacity
        }}
      ></div>
    </div>
  )
}


  
const Page1L = React.lazy(() => import('./page1'));
const Page2L = React.lazy(() => import('./page2'));
const Page3L = React.lazy(() => import('./page3'));
const Page4L = React.lazy(() => import('./page4'));






export default function App() {




  const isMobile = window.innerWidth < 768



  const divStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    // Add any additional styles here
  };


  const [section, setSection] = useState(1)
  const [menuOpened, setMenuOpened] = useState(false)
const [started,setStarted] = useState(false)
 



  useEffect(() => {
    setMenuOpened(false)
  }, [section])


  return (
    <>
    
      <div style={divStyle}>
        <LoadingScreen started={started} setStarted={setStarted} />
     
        <Router>
        <Routes>
       
            
          <Route index path="/*"  element={
            <React.Suspense fallback={<><div className="justify-center items-center flex animate-spin space-y-4 space-x-4 mb-8 py-8 transition-all w-full h-full">
            <div className="justify-center items-center rounded-full bg-indigo-200 w-16 h-16 md:w-24 md:h-24"> </div>
            <div className="justify-center items-center rounded-full bg-indigo-500 w-6 h-6 md:w-8 md:h-8"></div>
          
            </div>
            
            </>}>
              <Page1L/>
            </React.Suspense>
          }/>
         
         
          <Route  path="/home"  element={
            <React.Suspense fallback={<><div className="justify-center items-center flex animate-spin space-y-4 space-x-4 mb-8 py-8 transition-all w-full h-full">
            <div className="justify-center items-center rounded-full bg-indigo-200 w-16 h-16 md:w-24 md:h-24"> </div>
            <div className="justify-center items-center rounded-full bg-indigo-500 w-6 h-6 md:w-8 md:h-8"></div>
          
            </div>
            
            </>}>
              <Page1L/>
            </React.Suspense>
          }/>
  
          <Route path="/design"  element={
            <React.Suspense fallback={<><div className="justify-center items-center flex animate-spin space-y-4 space-x-4 mb-8 py-8 transition-all w-full h-full">
            <div className="justify-center items-center rounded-full bg-indigo-200 w-16 h-16 md:w-24 md:h-24"> </div>
            <div className="justify-center items-center rounded-full bg-indigo-500 w-6 h-6 md:w-8 md:h-8"></div>
          
            </div>
            
            </>
          }>
              <Page2L />
            </React.Suspense>
          }/>
          <Route path="/develop"  element={
            <React.Suspense fallback={<><div className="justify-center items-center flex animate-spin space-y-4 space-x-4 mb-8 py-8 transition-all w-full h-full">
            <div className="justify-center items-center rounded-full bg-indigo-200 w-16 h-16 md:w-24 md:h-24"> </div>
            <div className="justify-center items-center rounded-full bg-indigo-500 w-6 h-6 md:w-8 md:h-8"></div>
          
            </div>
            
            </>}>
              <Page3L />
            </React.Suspense>
          }/>
          <Route path="/contact"  element={
            <React.Suspense fallback={<><div className="justify-center items-center flex animate-spin space-y-4 space-x-4 mb-8 py-8 transition-all w-full h-full">
            <div className="justify-center items-center rounded-full bg-indigo-200 w-16 h-16 md:w-24 md:h-24"> </div>
            <div className="justify-center items-center rounded-full bg-indigo-500 w-6 h-6 md:w-8 md:h-8"></div>
          
            </div>
            
            </>}>
              <Page4L />
            </React.Suspense>
          }/>
        
        </Routes>
        
           
        <Menu onSectionChange={setSection} menuOpened={menuOpened} setMenuOpened={setMenuOpened} section={section} />
        </Router>
       
      </div>
      <Cursor />
      {!isMobile && <AudioButton/>}
      {/* <MenuManager section={section} onSectionChange={setSection} /> */}
      <Overlay/>

      {menuOpened && <MenuOverlay />} 

    </>
  );
}
