import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import './style.css'
import { CameraShake, CubeCamera, Environment, Fisheye, OrbitControls, PerspectiveCamera, Scroll, ScrollControls, Sparkles, Stage } from '@react-three/drei';
import AnimatedStars from './AnimatedStars';
import { Perf } from "r3f-perf";

import { Ground } from './Ground';

import * as THREE from 'three';
import Planes from './Plane';
import { Model5 } from './Spaceman5';

import Earth from './Earth';
import Lights from './Spotlights';
import { Interface } from './Interface';
import Text1 from './Text';


import { Scene } from './Volumetric';

import CameraPositionLogging from './CameraPositionLogging';
import { ScrollManager } from './components/ScrollManager';
import { Menu } from './components/Menu';
import { Cursor } from './components/Cursor';

import { Interface1 } from './interface_p1';
import { MenuManager } from './components/MenuManager';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

import Start from "./start";



function ModelShow() {


  
  // const [section, setSection] = useState(1)
 
  // console.log('section on p1',section)


const {viewport} = useThree()

  const mouse = useRef([0, 0]);
  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const isMobile = window.innerWidth < 768
const responisveRatio = viewport.width/12

  return (
    <>
      {/* <OrbitControls target ={[0,0.35,0]} maxPolarAngle={1.45}/> */}
      <group rotation={[0, Math.PI / 2, 0]} position={[4, 0, 0]}>
        <PerspectiveCamera makeDefault fov={isMobile?100:75} position={[0, 0, 0]} near={0.01} far={1000} />
      </group>
      <color args={[0, 0, 0]} attach="background" />

   

      <CubeCamera resolution={512} frames={Infinity}>
        {(texture) => (
          <>
            <Environment map={texture} />
            {/* <Spaceman/> */}
            
            <ScrollControls horizontal damping={0.2} maxSpeed={0.15} pages={5} >
              {/* <ScrollManager section = {section} onSectionChange = {setSection}/>  */}
          <Interface1/>

                {/* <Interface /> */}
                {/* <Design/>  */}
              <Model5 /> 
            </ScrollControls> 



    



           
            
            <Planes />






            {/* <spotLight
color={[0,1,0]}
intensity={5000}
angle={0.6}
penumbra={0.5}
position={[0,5,5]}
castShadow
shadow-bias={-0.0001}



/>  */}
            
            <Earth />

          {/* {!isMobile && <Sparkles count={500} scale={10} size={2} speed={0.4} />}
              {!isMobile && <Sparkles count={500} scale={0} size={2} speed={0.4} />} */}

            <Ground />
          </>
        )}
      </CubeCamera>


    


      <AnimatedStars />

      <spotLight
        color={[1, 0.25, 0.7]}
        intensity={100.5}
        angle={0.6}
        penumbra={0.5}
        position={[5, 5, 0]}
        castShadow
        shadow-bias={-0.0001}
      />

      <spotLight
        color={[0.14, 0.5, 1]}
        intensity={500}
        angle={0.6}
        penumbra={0.5}
        position={[-5, 5, 0]}
        castShadow
        shadow-bias={-0.0001}
      />

      <spotLight
        color={[1, 0.5, 1]}
        intensity={5000}
        angle={0.6}
        penumbra={0.5}
        position={[20, 5, -40]}
        castShadow
        shadow-bias={-0.0001}
      />



      <spotLight
        color={[1, 0.5, 1]}
        intensity={5000}
        angle={0.6}
        penumbra={0.5}
        position={[-10, 5, -40]}
        castShadow
        shadow-bias={-0.0001}

      />


      {/* <spotLight
        angle={0.12}
        penumbra={0.1}
        position={[10, 0, -10]}
        intensity={40}
        onUpdate={(self) => {
          self.target.position.set(-10, 0, 0)
          self.target.updateMatrixWorld()
        }}
      /> */}

      {/* <ambientLight intensity={0.6} /> */}


      <CameraShake yawFrequency={1} maxYaw={0.05} pitchFrequency={1} maxPitch={0.05} rollFrequency={0.5} maxRoll={0.5} intensity={0.2} />
    







    </>
  )
}

export default function Page1() {

 


  return (
    <Suspense fallback={null}>
    
      <Canvas gl={{ antialias: false, stencil: false }} shadows>

  
      <ModelShow/>
   

    
             
              
            
         
  

   
        <CameraPositionLogging event="mousedown" />
      
      </Canvas>
  
   



    </Suspense>

  )
}

