import { useState } from "react";
import { route,useNavigate,useLocation } from "react-router-dom";


export const Menu = (props,route) => {
  const { onSectionChange, menuOpened, setMenuOpened, section } = props;
const navigate = useNavigate()
const location = useLocation();
// const section_p1 = location.state.section


const openInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
};



  return (
    <>
   
  
      <button
        onClick={() => setMenuOpened(!menuOpened)}
        className="z-20 fixed top-4 right-4 md:top-12 md:right-12 p-3 bg-indigo-600 w-11 h-11 rounded-md"
      >
        <div
          className={`bg-white h-0.5 rounded-md w-full transition-all ${
            menuOpened ? "rotate-45  translate-y-0.5" : ""
          }`}
        />
        <div
          className={`bg-white h-0.5 rounded-md w-full my-1 ${
            menuOpened ? "hidden" : ""
          }`}
        />
        <div
          className={`bg-white h-0.5 rounded-md w-full transition-all ${
            menuOpened ? "-rotate-45" : ""
          }`}
        />
      </button>
      
      <div
        className={`z-10 fixed top-0 right-0 bottom-0 bg-white transition-all overflow-hidden flex flex-col
      ${menuOpened ? "w-full md:w-80" : "w-0"}`}
      >
        <div className="flex-1 flex items-start justify-center flex-col gap-6 p-8">
          <MenuButton label="Home" onClick={() => navigate('/home')} onPointerUp={() => onSectionChange(1)}  active={section=== 1 && (!location.state)} />
          <MenuButton label="Design" onClick={() => navigate('/design')} onPointerUp={() => onSectionChange(2)}  active={section === 2 || (location.state && location.state.section === 2)}  />
          <MenuButton label="Develop" onClick={() => navigate('/develop')} onPointerUp={() => onSectionChange(3)}  active={section === 3  || (location.state && location.state.section === 3)} />
          <MenuButton label="Contact" onClick={() => navigate('/contact')} onPointerUp={() => onSectionChange(4)}  active={section === 4  && (!location.state)} />
       

     
       <div className="relative">
       <div className="bg-gray-200 absolute top-40 md:top-48 rounded-full w-24 h-0.5"></div>
          <div className="flex space-x-4 absolute top-44 md:top-52"> 
                
                <button onClick={() => openInNewTab("https://www.instagram.com/definitelyjayant")}>
                <svg

                    xmlns="http://www.w3.org/2000/svg"
                    className=" h-5 w-5 md:h-6  md:w-6 transition ease-in-out delay-150 text-gray-400 hover:scale-110 hover:text-indigo-600 duration-300 focus:text-indigo-600 cursor-pointer"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                    <path
                        d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
                </button>
                <button onClick={() => openInNewTab("https://www.linkedin.com/in/definitelyjayant")}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className=" h-5 w-5 md:h-6  md:w-6  transition ease-in-out delay-150 text-gray-400 hover:scale-110 hover:text-indigo-600 duration-300 focus:text-indigo-600 cursor-pointer"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                    <path
                        d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                </svg>
                </button>
            </div>
            </div>
       
        </div>
        
   


      </div>
      
    </>
  );
};

const MenuButton = (props) => {
  const { label, onClick, active, onPointerUp ,location,} = props;

  return (
    <button
      onClick={onClick}
      onPointerUp={onPointerUp}
      location={location}
      className={`text-2xl font-bold cursor-pointer border-none focus:outline-none hover:text-indigo-600 focus:text-indigo-800 active:text-indigo-200 transition-colors 
      ${active ? " ring-2 ring-offset-8 ring-indigo-400" : ''} `}
    >
      {label}
    </button>
  );
};
