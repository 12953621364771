import { Scroll } from "@react-three/drei";
import { motion } from "framer-motion";
import { useForm, ValidationError } from '@formspree/react';


const Section = (props) => {

    const { children, mobileTop } = props

    return (
        <motion.section
            className={`
        h-screen w-screen p-8 max-w-screen-2xl mx-auto
        flex flex-col items-start justify-center
        `}
            initial={{
                opacity: 0,
                x: -50,
            }}
            whileInView={{
                opacity: 1,
                x: 0,
                transition: {
                    duration: 1,
                    delay: 0.7,
                }

            }}
        >
            {children}
        </motion.section>
    )

}


export const Interface4 = () => {
    return (
        <Scroll html>
            <div className="flex flex-col items-center w-screen">


                <ContactSection />





            </div>
        </Scroll>
    )
}


const ContactSection = () => {
    const [state, handleSubmit] = useForm("xnqenyjd");

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
      };
   
    return (
        <Section mobileTop>
            <h2 className=" text-3xl md:text-5xl font-bold text-gray-200">Contact me</h2>
            <div className="mt-8 p-8 rounded-md  bg-white w-full md:w-96  max-w-full">

                {state.succeeded ? (
                    <p className="text-indigo-600 text-center"> Thank you for your time</p>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <label for="name" className="font-medium text-gray-800 block mb-1">
                            Name
                        </label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full rounded-md border-0 text-gray-950 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 p-3"
                        />
                        <label
                            for="email"
                            className="font-medium text-gray-800 block mb-1 mt-8"
                        >
                            Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 p-3"
                        />
                        <ValidationError
                            prefix="Email"
                            field="email"
                            errors={state.errors}
                            className="mt-2 text-red-500"
                        />
                        <label
                            for="email"
                            className="font-medium text-gray-900 block mb-1 mt-8"
                        >
                            Message
                        </label>
                        <textarea
                            name="message"
                            id="message"
                            className="h-32 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 p-3"
                        />
                        <ValidationError
                            className="mt-2 text-red-500"
                            errors={state.errors}
                        />
                        <div className="flex relative mt-4 md:mt-0 flex-col justify-start items-start">
                            <div className="bg-gray-200 absolute bottom-8 rounded-full w-20 h-0.5"></div>
                            <motion.button disabled={state.submitting} className="text-white transition ease-in-out delay-150 bg-indigo-600 hover:bg-indigo-400 duration-300 py-4 px-8 rounded-lg font-bold text-md md:text-lg mt-4 md:mt-12">
                                Submit
                            </motion.button>

                            <div className="flex mt-8 pt-4 space-x-4 justify-end">

                            <button onClick={() => openInNewTab("https://www.instagram.com/definitelyjayant")}>
                                    <svg

                                        xmlns="http://www.w3.org/2000/svg"
                                        className=" h-5 w-5 md:h-5  md:w-5 transition ease-in-out delay-150 text-gray-400 hover:scale-110 hover:text-indigo-600 duration-300 focus:text-indigo-600 cursor-pointer"
                                        fill="currentColor"
                                        viewBox="0 0 24 24">
                                        <path
                                            d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                                    </svg>
                                </button>
                                <button onClick={() => openInNewTab("https://www.linkedin.com/in/definitelyjayant")}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className=" h-5 w-5 md:h-5  md:w-5 transition ease-in-out delay-150 text-gray-400 hover:scale-110 hover:text-indigo-600 duration-300 focus:text-indigo-600 cursor-pointer"
                                        fill="currentColor"
                                        viewBox="0 0 24 24">
                                        <path
                                            d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </form>

                )}
            </div>
        </Section>
    )
}

