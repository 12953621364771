import React, { useRef } from 'react';
import { Text } from '@react-three/drei';
import { suspend } from "suspend-react";

const CustomText = ({ children, position, color, fontSize, opacity, font }) => {
  const textRef = useRef();





  return (
    <group position={position}>
      <mesh ref={textRef}>
        <Text color={color} fontSize={fontSize} anchorX="center" anchorY="middle" rotation={[0, Math.PI / 2, 0]} material-opacity={opacity} font={font} >
          {children}
        </Text>
      </mesh>
    </group>
  );
};

export default CustomText;
