import React, {useEffect} from "react";
import { MeshReflectorMaterial, Sparkles, useTexture, Reflector, Wireframe } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import * as THREE from 'three'
import {LinearEncoding, LinearSRGBColorSpace, RepeatWrapping, TextureLoader } from "three";
import Renderer from "three/examples/jsm/renderers/common/Renderer";


export function Ground()
{

    /* const [normal, roughness] = useLoader(TextureLoader, 
         [ "/textures/terrain-normal.jpg", "/textures/terrain-roughness.jpg"]);
*/
    // const [normal,roughness,displacement] = 

    // useTexture([
    //     '/textures/terrain-normal.jpg', 
    //     '/textures/terrain-roughness.jpg', 
    //     '/textures/terrain-displacement.png', 
    //  ])


     const [normal, roughness] = useLoader(TextureLoader, [
     
         process.env.PUBLIC_URL + "/textures/normal3.jpg",
    //    process.env.PUBLIC_URL + "/textures/terrain-roughness.jpg",
       process.env.PUBLIC_URL + "/textures/roughness3.png",
      ]);

       useEffect(()=>{
            [normal, roughness].forEach((t) => {
                t.wrapS = RepeatWrapping
                t.wrapT = RepeatWrapping
                t.repeat.set(20,20)
                t.offset.set(0, 0);
           
            });
            normal.colorSpace = LinearSRGBColorSpace
            
            // roughness.colorSpace = LinearSRGBColorSpace
        }, [normal, roughness])

// useFrame((state, delta)=>{
// let t = -state.clock.getElapsedTime()*0.128
// roughness.offset.set(0,t)
// normal.offset.set(0,t)
// })


    return(
     <mesh rotation-x={-Math.PI * 0.5}
     castShadow receiveShadow renderOrder={1} >
         <planeGeometry args={[30,30]}/>
          <MeshReflectorMaterial
         envMapIntensity={0}
         normalMap={normal}
         normalScale={[0.1,0.1]}
         roughnessMap={roughness}
         // displacementMap={displacement}
         // displacementScale={1.5}
         dithering={true}
         color={[0.015, 0.015, 0.015]}
         roughness={0.2}
         blur={[1000,400]}
         mixBlur={15}
         mixStrength={80}
         mixContrast={1}
         resolution={1024}
         mirror={0}
         depthScale={0.01}
         minDepthThreshold={0.9}
         maxDepthThreshold={1}
         depthToBlurRatioBias={0.25}
         debug={0}
         reflectorOffset={0}
       depthWrite={false}

    />  
  
 {/* <meshStandardMaterial metalness={1} color={'black'} roughness={1.02} roughnessMap={roughness} ref />  */}

        
    </mesh>

/* <Reflector
blur={[1000, 400]}
resolution={1024}
args={[30, 30]}
mirror={0}
mixBlur={15}
mixStrength={80}
mixContrast={1}
rotation-x={-Math.PI * 0.5 }
>
{(Material, props) => (
  <Material
  color={[0.015, 0.015, 0.015]}
    // color="#000000"
    depthScale={0.001}
    roughnessMap={roughness}
    normalMap={normal}
    normalScale={[0.1, 0.1]}
    roughness={0.2}
    mirror={0.1}
    {...props}
  />
}
</Reflector> */

    


)
}
