import { Sparkles, Stars } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useMemo } from "react";
import { useRef } from "react";

const AnimatedStars = () => {
  const starsRef = useRef()

  const starProps = useMemo(
    () => ({
      radius: 1,
      depth: 150,
      count: 5000,
      factor: 5,
      saturation: 0,
      fade: true,
      speed: 1,
    }),
    []
  )



  useFrame(() => {
    starsRef.current.rotation.x += 0.0005
    starsRef.current.rotation.y += 0.0005
    starsRef.current.rotation.z += 0.0005
  })
  return (
  <>
    <Stars ref={starsRef} {...starProps} />
    {/* <Sparkles count={500} scale={30} size={2} speed={0.4} />
    <Sparkles count={500} scale={30} size={2} speed={0.4} /> */}
  </>
  )
}

export default AnimatedStars