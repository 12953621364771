import { useProgress } from "@react-three/drei";
import { useEffect } from "react";
import { motion } from "framer-motion";
export const LoadingScreen = (props) => {
  const { started, setStarted } = props;
  const { progress, total, loaded, item } = useProgress();

  useEffect(() => {
    // console.log(progress, total, loaded, item);
    if (progress === 100) {
      setTimeout(() => {
        setStarted(true);
      }, 500);
    }
  }, [progress, total, loaded, item]);

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full z-50 transition-opacity duration-1000 pointer-events-none
  flex flex-col items-center justify-center bg-gray-950 
  ${started ? "opacity-0" : "opacity-100"}`}
    >

<div className=" space-y-4 mb-8 py-8 transition-all animate-spin">
      <div className="rounded-full bg-indigo-200 w-16 h-16 md:w-24 md:h-24"> </div>
      <div className="rounded-full bg-indigo-500 w-6 h-6 md:w-8 md:h-8"></div>
      </div>

       <div className="text-sm md:text-lg  text-slate-100 relative">
        <div
          className="absolute left-0 top-0  overflow-hidden truncate text-clip transition-all duration-500"
          style={{
            width: `${progress}%`,
          }}
        >
          Great things are coming your way
        </div>
        <div className="opacity-40"> Great things are coming your way</div>
     
      </div> 
      
      <p className="visible md:invisible items-center justify-center mt-2 text-gray-600 text-xs" > Best viewed on desktop</p>
 
   
    </div>
  );
};