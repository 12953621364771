/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 spaceman2.glb 
*/

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useGLTF, useAnimations, useScroll, ScrollControls, Text, Wireframe } from '@react-three/drei'
import { Mesh, Vector3 } from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import { EffectComposer,TiltShift2 } from '@react-three/postprocessing';
import CameraPositionLogging from './CameraPositionLogging';
import { Ground } from './Ground';
import { Interface4 } from './interface_cu';











export function Model5(props) {

  const { camera } = useThree()

  const scroll = useScroll()

  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/Models/Racer2/spaceman5.glb')

  

  const { actions, names } = useAnimations(animations, group)
  const [hovered, hover] = useState(false)


  const [animationFinished, setAnimationFinished] = useState(false);

  const [modelRotation, setModelRotation] = useState(
    new THREE.Vector3(0, -1.5, 0)
)



  useEffect(() => {


    if (group.current) {
      group.current.scale.set(1,1,1);
      group.current.rotation.set(0,3.14,0)
      group.current.position.set(0, -0.00000001, -1.8);
      group.current.traverse((object) => {
        if (object instanceof Mesh) {
          object.castShadow = true;
          object.receiveShadow = true;
          object.frustumCulled = false;
          // Since you used useGLTF, mapIntensity isn't required
          object.material.envMapIntensity = 20;
        }
      });
    }
  }, [group]);





  









  const speedFactor = 0.8;

  useEffect(() => void (actions.WALK6.reset().play().paused = true), [])
  useFrame(() => {
    if (actions.WALK6) {
      const totalDuration = actions.WALK6.getClip().duration;
      const newTime = totalDuration * scroll.offset ;
      actions.WALK6.time = Math.min(newTime, totalDuration);

      if (scroll.offset === 1 && !animationFinished) {
        setAnimationFinished(true);
  
      
      }




    }

    // Update camera position to follow the model based on the scroll position
    const distance = 5; // Adjust the distance between the camera and the model
    const angle = Math.PI / 2 * scroll.offset * speedFactor; // Adjust the angle based on the scroll offset
    const x = Math.sin(angle) * distance;
    const z = Math.cos(angle) * distance;



     camera.position.set(x * 1.5, 0.7,7);

   
    camera.lookAt(group.current.position);
  
    camera.updateProjectionMatrix();
  });




//  useEffect(()=>{


    

//     document.body.style.cursor = hovered ? 'pointer' : 'auto'

//     if(hovered)
// {
//   // group.current.rotation.set(0,3.14,0);
//     actions.WALK3.reset().fadeIn(0.5).play()
//     actions.IDLE.fadeOut(0.5)
// }
// else{

 

//   // group.current.rotation.set(0,-1.5,0);
//   actions.IDLE.reset().fadeIn(0.5).play()
//   actions.WALK3.fadeOut(0.5)
// }


//     }, [hovered])


    const animate = (t) => {
      TWEEN.update(t)
      window.requestAnimationFrame(animate)
    }
  animate();


    
   


  const originalrotat = new Vector3(0,-1.5708,0)
  const rotat =  new Vector3(0,3.14,0)



    //  const tween1 = new TWEEN.Tween({yRotation: Math.PI})
    //   .to({yRotation: Math.PI}, 1000)
    //   .easing(TWEEN.Easing.Quadratic.InOut)
    //   .onUpdate((coords)=>{
      
      
    //     group.current.rotation.y = coords.yRotation
    //   })
      
    //  tween1.start()





 
     return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Armature" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <primitive object={nodes.mixamorig6Hips} />
          <skinnedMesh name="Ch20" geometry={nodes.Ch20.geometry} material={materials.Ch20_body} skeleton={nodes.Ch20.skeleton} />
         
        </group>
      </group>
       {/* {animationFinished && <Interface4/>}  */}
      <CameraPositionLogging event="mousedown"/>
    </group>
  )

  
}

useGLTF.preload('/Models/Racer2/spaceman5.glb')
