import { Sparkles, useTexture } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import React, { useRef, useCallback, useState } from "react"
import * as THREE from 'three'


const Earth = React.memo(() => {

    const EarthRef = useRef()
    const clockRef= useRef(new THREE.Clock())

    const [hovered, hover] = useState(false)

    const [earthTexture, earthNormalMap, 
        earthSpecularMap, earthDisplacementMap, earthEmissiveMap] = 

    useTexture([
        '/textures/earth_day.jpg', 
        '/textures/earth_normal.jpeg',
        '/textures/earth_specular.jpg',
        '/textures/earth_displacment.jpg',
        '/textures/earth_night.jpeg'
    ])
    const xAxis = 4

const updateEarthPosition = useCallback(() => {

    // Orbit Rotation
  //  EarthRef.current.position.x = Math.sin(clockRef.current.getElapsedTime() * 0.8) * xAxis
   // EarthRef.current.position.z = Math.cos(clockRef.current.getElapsedTime() * 0.8) * xAxis
    // Axis rotation
    EarthRef.current.rotation.y += 0.002


},[])


useFrame(({})=> {
updateEarthPosition()

}
     )

    return (
        <group ref={EarthRef} position={[-20,5,-5]} scale={[1,1,1]} >
        <mesh castShadow receiveShadow >
           
            <sphereGeometry args = {[2, 32, 32]}/>
            <meshPhongMaterial 
            map = {earthTexture} 
            normalMap = {earthNormalMap} 
            specularMap = {earthSpecularMap}
            shininess={200}
            emissiveMap={earthEmissiveMap}
            emissiveIntensity = {2}
            emissive={0xffffff}
            displacementMap = {earthDisplacementMap}
            displacementScale={0.1}
            />
        </mesh>

        </group>
    )
})

export default Earth
useTexture.preload(   '/textures/earth_day.jpg', 
'/textures/earth_normal.jpeg',
'/textures/earth_specular.jpg',
'/textures/earth_displacment.jpg',
'/textures/earth_night.jpeg')