import { Float, Html, Outlines, PerspectiveCamera, RenderTexture, Sparkles, SpotLight, Text, Text3D, meshBounds, useDepthBuffer, useGLTF } from "@react-three/drei";
import React, { useMemo, useState, useEffect, useRef } from "react";
import * as THREE from 'three'
import url from './develop_copy.mp4'
import url2 from './design_copy.mp4'
import { LinearEncoding, LinearSRGBColorSpace, RepeatWrapping, TextureLoader } from "three";
import { useFrame, useLoader, extend, useThree } from "@react-three/fiber";
import { MeshReflectorMaterial, useTexture, shaderMaterial, MeshRefractionMaterial, MeshTransmissionMaterial } from "@react-three/drei";
import { RGBELoader } from 'three-stdlib'
import GradientFbmMaterial from './GradientFbmMaterial/GradientFbmMaterial'
import { easing } from 'maath'
import { WaveMaterial } from './WaveMaterial'
import { useControls } from 'leva'
import { Portal } from "@radix-ui/react-portal";
import { MeshBasicMaterial } from "three";
import { MeshStandardMaterial } from "three";
import CustomText from "./Materialtext";
import { EffectComposer, GodRays } from "@react-three/postprocessing";
import { suspend } from "suspend-react";
import { Vector3 } from "three";
import { lerp } from "three/src/math/MathUtils";
import { Link, NavLink, Navigate, useLocation, useNavigate } from "react-router-dom";


const inter = import("./fonts/ExpletusSans-Bold.ttf");










const Planes = React.memo(() => {


  const navigate = useNavigate();

  // const lastSection = useRef(1);

// const [changeTriggered, setChangeTriggered] = useState(false);



  // useEffect(() => {
  //   // Use the navigate function to change routes
  //   console.log('1st section',section)
  //   navigate(`/page${section}`,{replace:true});
  //   console.log('2nd section',section)
  // }, [section, navigate]);

  // useEffect(() => {
  //   // Handle section changes when routes change
  //   const pathname = window.location.pathname;
  //   const currentSection = parseInt(pathname.replace('/page', ''), 10) || 1;
  //   console.log('current',currentSection)
  //   if (currentSection !== lastSection.current) {
  //     console.log('current',currentSection,'last',lastSection)
  //     onSectionChange(currentSection);
  //     console.log('current 2nd ',currentSection)
  //     console.log('satisfya')
  //     // setChangeTriggered(true); 
  //   }
  //   lastSection.current = currentSection;
  //   console.log('current 3rd ',currentSection)
  // }, [onSectionChange]);




  // const texture = useLoader(RGBELoader, 'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/peppermint_powerplant_2_1k.hdr')

  // const texture = useLoader(RGBELoader, '/textures/hdri.pic')
  //  texture.mapping = THREE.EquirectangularReflectionMapping



  // const ref2 = useRef()


  // useFrame((state, delta) => {
  //     ref2.current.time += delta
  //     easing.damp3(ref2.current.pointer, state.pointer, 0.2, delta)
  //   })



  const ref3 = useRef()


  // const config = useControls({
  //   meshPhysicalMaterial: false,
  //   transmissionSampler: false,
  //   backside: true,
  //   backsideThickness: { value: 2, min: -10, max: 10 },
  //   samples: { value: 10, min: 0, max: 32, step: 1 },
  //   resolution: { value: 2048, min: 256, max: 2048, step: 256 },
  //   backsideResolution: { value: 1024, min: 32, max: 2048, step: 256 },
  //   transmission: { value: 1, min: 0, max: 1 },
  //   roughness: { value: 0.0, min: 0, max: 1, step: 0.01 },
  //   ior: { value: 1.5, min: 1, max: 5, step: 0.01 },
  //   thickness: { value: 0.25, min: 0, max: 10, step: 0.01 },
  //   chromaticAberration: { value: 0.4, min: 0, max: 1 },
  //   anisotropy: { value: cc0.3, min: 0, max: 1, step: 0.01 },
  //   distortion: { value: 0.0, min: 0, max: 1, step: 0.01 },
  //   distortionScale: { value: 0.3, min: 0.01, max: 1, step: 0.01 },
  //   temporalDistortion: { value: 0.65, min: 0, max: 1, step: 0.01 },
  //   attenuationDistance: { value: 0.5, min: 0, max: 2.5, step: 0.01 },
  //   clearcoat: { value: 0, min: 0, max: 1 },
  //   attenuationColor: '#ffffff',
  //   color: 'white'
  // })




  

  

   const [hovered, hover] = useState()
   const [hovered2, hover2] = useState()



   const [emissive, setEmissive] = useState(2);






   const targetEmissive = hovered ? 20 : 2;

  useEffect(()=>{
    document.body.style.cursor = hovered ? 'pointer' : 'auto'
    document.body.style.cursor = hovered2 ? 'pointer' : 'auto'
    setEmissive((currentEmissive) => lerp(currentEmissive, targetEmissive, 0.5));
    }, [hovered,hovered2])

   // Adjust the interpolation factor (0.1) as needed



//light.current.target.position.lerp(vec.set((state.mouse.x * viewport.width) / 2, (state.mouse.y * viewport.height) / 2, 0), 0.1)


  const ref = useRef()

  const [video] = useState(() => {
    const vid = document.createElement("video")
    vid.src = url2
    vid.crossOrigin = "Anonymous"
    vid.loop = true
    vid.muted = true
    // vid.playsinline= true
    var playPromise = vid.play();

  if (playPromise !== undefined) {
    playPromise.then(_ => {
      // Automatic playback started!
      // Show playing UI.
    })
    .catch(error => {
      // Auto-play was prevented
      // Show paused UI.
    });
  }
    // vid.play()
    return vid
  })

  const [video2] = useState(() => {
    const vid2 = document.createElement("video")
    vid2.src = url
    vid2.crossOrigin = "Anonymous"
    vid2.loop = true
    vid2.muted = true
    var playPromise2 = vid2.play();

    if (playPromise2 !== undefined) {
      playPromise2.then(_ => {
        // Automatic playback started!
        // Show playing UI.
      })
      .catch(error => {
        // Auto-play was prevented
        // Show paused UI.
      });
    }


    // vid2.play()
    return vid2
  })


  const [normal, roughness] = useLoader(TextureLoader, [

    process.env.PUBLIC_URL + "/textures/normal2.png",
    //    process.env.PUBLIC_URL + "/textures/terrain-roughness.jpg",
    process.env.PUBLIC_URL + "/textures/roughness2.jpg",
  ]);

  useEffect(() => {
    [normal, roughness].forEach((t) => {
      t.wrapS = RepeatWrapping
      t.wrapT = RepeatWrapping
      t.repeat.set(10, 10)
      t.offset.set(0, 0);

    });
    normal.colorSpace = LinearSRGBColorSpace
    // roughness.colorSpace = LinearSRGBColorSpace
  }, [normal, roughness])

  const { nodes } = useGLTF("/Models/Portraits/frames.glb");

  // const textRef = useRef();
  // useFrame(
  //   (state) =>
  //     (textRef.current.position.x = Math.sin(state.clock.elapsedTime) * 2),
  // );



  const depthBuffer = useDepthBuffer({ frames: 1 })





  function MovingSpot({ vec = new Vector3(), ...props }) {
    const light = useRef()
    const viewport = useThree((state) => state.viewport)
 
    // useFrame((state) => {
    //     light.current.target.position.lerp(vec.set((state.mouse.x * viewport.width) / 2, (state.mouse.y * viewport.height) / 2, 0), 0.1)
    //     light.current.target.updateMatrixWorld()
    //   })
    return <SpotLight  ref={light} penumbra={1000} distance={100} angle={3} attenuation={hovered?5000:0} anglePower={10} intensity={500} {...props} target-position={[0,-2,-8.5]} />
  }

  function MovingSpot2({ vec = new Vector3(), ...props }) {
    const light2 = useRef()
    const viewport = useThree((state) => state.viewport)
 
    // useFrame((state) => {
    //     light.current.target.position.lerp(vec.set((state.mouse.x * viewport.width) / 2, (state.mouse.y * viewport.height) / 2, 0), 0.1)
    //     light.current.target.updateMatrixWorld()
    //   })
    return <SpotLight castShadow ref={light2} penumbra={1} distance={6} angle={0.35} attenuation={5} anglePower={4} intensity={2} {...props} target-position={[0,0,-2]} />
  }






// alternate way with mesh basic material
  //const alt_texture = useVideoTexture(url)
 // <meshBasicMaterial map={texture} toneMapped={false} />



  return (
    <>
    {/* volumetric spotlight */}
    {/* <group>
    <MovingSpot depthBuffer={depthBuffer} color="#0c8cbf" position={[-5, 17, -10.3]}/>
        <MovingSpot2 depthBuffer={depthBuffer} color="#b00c3f" position={[0, 6, -1]} />

    </group> */}
    <group rotation={[0, 0, 0]} position={[-5, 0, 0]} scale={[.7, .7, .7]} >



      {/* <Text
        position={[0, 11.5, 0]} // Adjust the position as needed
        rotation={[0, Math.PI / 2, 0]} // Adjust the rotation as needed
        fontSize={1} // Adjust the font size as needed
        color="white" // Adjust the text color as needed
        anchorX="center"
        anchorY="middle"
     
      >
        Design
      </Text> */}


      <CustomText position={[0, 11.5, 0]} color="aqua" fontSize={1} opacity={hovered2? 1: 0.3}   font={suspend(inter).default}  onPointerOver={()=> hover2(true)}
    onPointerOut={()=>hover2(false)} >
        Design
      </CustomText>
     
    


      {/* <Sparkles count={200} scale={20} size={4} speed={0.4}  /> */}


      {/* Design outer frame */}
      <mesh  geometry={nodes.frame.geometry} scale={[1.2, 1.16, 1.2]} onPointerOver={()=> hover2(true)}
    onPointerOut={()=>hover2(false)} glow="lightgreen" raycast={meshBounds}   renderOrder={0} >


        
        {/* <MeshReflectorMaterial
        envMapIntensity={0}
        roughnessMap={roughness}
        dithering={true}
        color={[0.015, 0.015, 0.015]}
        roughness={0}
        blur={[1000,400]}
        mixBlur={30}
        mixStrength={80}
        mixContrast={1}
        resolution={1024}
        mirror={0}
        depthScale={0.01}
        minDepthThreshold={0.9}
        maxDepthThreshold={1}
        depthToBlurRatioBias={0.25}
        debug={0}
        reflectorOffset={0.2}
        />  */}
        {/* <GradientFbmMaterial iridescence={0.6} color={'grey'} gridWidth={0} gridHeight={35}  /> */}
        {/* <ImageFadeMaterial ref={ref} toneMapped ={false}/> */}
        {/* <MeshRefractionMaterial envMap={texture} aberrationStrength={0.01} toneMapped={true} ior={1.9} fresnel={1}  />  */}
        {/* <meshPhongMaterial color="#ffffff" opacity={0.2} transparent envMap={texture} /> */}
        {/* {config.meshPhysicalMaterial ? <meshPhysicalMaterial color={config.color} {...config} /> : <MeshTransmissionMaterial {...config} toneMapped={true} />} */}
        {/* <waveMaterial ref={ref2} key={WaveMaterial.key} /> */}
        {/* <meshPhysicalMaterial 
        roughness={0.}
        transmission={1}
        thickness={0.5} /> */}

        {/* <meshStandardMaterial emissive={'white'} emissiveIntensity={5}/> */}

        <meshStandardMaterial emissive={"white"} emissiveIntensity={hovered2?20:2} side={THREE.DoubleSide} >
          <videoTexture attach="map" args={[video]} />
          <videoTexture attach="emissiveMap" args={[video]} />
        </meshStandardMaterial>
      </mesh>




    

      {/* Develop outer frame */}
      <mesh  geometry={nodes.frame.geometry} scale={[1.2, 1.16, 1.2]} position={[0, 0, -15]}  onPointerOver={()=> hover(true)}
    onPointerOut={()=>hover(false)} glow="lightgreen" raycast={meshBounds}  renderOrder={0}>
      
        {/* <MeshRefractionMaterial envMap={texture} aberrationStrength={0.01} toneMapped={true} ior={1.9} fresnel={1}  />  */}
        <meshStandardMaterial emissive={"white"} emissiveIntensity={hovered ? 20 : 2} side={THREE.DoubleSide}>
          <videoTexture attach="map" args={[video2]} />
          <videoTexture attach="emissiveMap" args={[video2]} />
        </meshStandardMaterial>
      
      </mesh>
     
      {/* <Sparkles count={500} scale={20} size={3} speed={0.4} position={[0,5,0]}   /> */}
     
      {/* <Text
       font={suspend(inter).default}
        position={[0, 11.5, -15]} // Adjust the position as needed
        rotation={[0, Math.PI / 2, 0]} // Adjust the rotation as needed
        fontSize={1} // Adjust the font size as needed
        color="white" // Adjust the text color as needed
        anchorX="center"
        anchorY="middle"
      >
        Develop
      </Text> */}




<CustomText position={[0, 11.5, -15]} color="magenta" fontSize={1} opacity={hovered? 1 : 0.3}   font={suspend(inter).default}  onPointerOver={()=> hover(true)}
    onPointerOut={()=>hover(false)} >
        Develop
        {/* <Sparkles count={200} scale={20} size={5} speed={0.4}  /> */}
      </CustomText>
  


      {/* Design inner frame */}
     
      <mesh  geometry={nodes.picture.geometry} scale={[1.2, 1.2, 1.2]} position={[0, -0.15, 0]}  renderOrder={0} onPointerDown={()=>navigate('/design',{replace: true,state:{section: 2}})} >

        <meshStandardMaterial emissive={"white"} side={THREE.DoubleSide}>
          <videoTexture attach="map" args={[video]} />
          <videoTexture attach="emissiveMap" args={[video]} />
        </meshStandardMaterial>

      
      
    

      </mesh>
     


      {/* Develop inner frame */}
      <mesh geometry={nodes.picture.geometry} position={[0, 0, -15]} scale={[1.2, 1.18, 1.2]} renderOrder={0} onPointerDown={()=>navigate('/develop',{replace: true,state:{section: 3}})} >

        <meshStandardMaterial emissive={"white"} side={THREE.DoubleSide}>
          <videoTexture attach="map" args={[video2]} />
          <videoTexture attach="emissiveMap" args={[video2]} />
        </meshStandardMaterial>
      </mesh>

      {/* 
      <mesh position={[0.5,7,0]}>
      <boxGeometry args={[1,2,5]} />
      <meshStandardMaterial>
        <RenderTexture attach="map" anisotropy={8}>
          <PerspectiveCamera
            makeDefault
            manual
            aspect={1 / 1}
            position={[0, 0, 5]}
          />
          <color attach="background" args={["white"]} />
      
   
          <Text
            font={suspend(inter).default}
            ref={textRef}
            fontSize={4}
            color="#555"
          >
           Design
          </Text>
        
        </RenderTexture>
      </meshStandardMaterial>
    </mesh> */}

    </group>
    </>


  )
})

export default Planes
useTexture.preload("/textures/normal2.png","/textures/roughness2.jpg")



