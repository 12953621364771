
import { useState } from 'react';
import url from '../audio2.wav'

const audio = new Audio(url);
export const AudioButton = () => {
  

const [isPlaying, setIsPlaying] = useState(false)


 function play()
{

  audio.play();
  audio.loop = true;
  setIsPlaying(true);

  audio.volume = 0.1;
  audio.muted = false

 }

 function pause() {

    audio.pause(); // Pause the audio
    setIsPlaying(false);
    audio.volume = 0.0;
    audio.muted = true
  
}



  return(
    <>
    {/* ulta logic works here so don't touch */}

  {isPlaying ? (
<button  onClick={pause} className="z-20 fixed rotate-90 md:top-12 md:right-32 p-3 w-11 h-11 rounded-md">
<div className="bg-gray-500 h-0.5 animate-[pulse_1.5s_ease-in-out_infinite] rounded-md w-full transition-all"> </div>
<div className="bg-gray-500 h-0.5 animate-[pulse_2s_ease-in-out_infinite] rounded-md w-full transition-all my-1"> </div>
<div className="bg-gray-500 h-0.5 animate-[pulse_1.5s_ease-in-out_infinite] rounded-md w-full transition-all my-1"> </div>
<div className="bg-gray-500 h-0.5 animate-[pulse_2s_ease-in-out_infinite] rounded-md w-full transition-all my-1"> </div>


</button>
) :
(
  <button onClick={play} className="z-20 fixed  rotate-90 md:top-12 md:right-32 p-3 w-11 h-11 rounded-md">
<div className="bg-gray-500 h-0.5 rounded-md w-full transition-all"> </div>
<div className="bg-gray-500 h-0.5 rounded-md w-full transition-all my-1"> </div>
<div className="bg-gray-500 h-0.5 rounded-md w-full transition-all my-1"> </div>
<div className="bg-gray-500 h-0.5 rounded-md w-full transition-all my-1"> </div>



  </button>
)}

</>

)

}

